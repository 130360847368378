<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999' attach)
    loadingOverlay(v-if='delLoading' message='Loading . . .' :zIndex='999' attach)
            v-progress-circular(indeterminate color='primary')
    div.height_100(v-if='!loading')
      div(:href='"/upgpt/Chatbot"').d-flex.align-start.justify-start.pl-9
        v-breadcrumbs.pa-0.px-3.pb-2.ellipsis()
          v-breadcrumbs-item(v-for="item in breadcrumbs" :key="item.text" )
            .detail_a
              a(:href='item.href')
                v-icon(size='23' style='margin-bottom:2px;').pr-1 mdi-chevron-double-left
                span {{item.text}}
      #page-inner.pa-3.d-flex.align-center.justify-center.pl-13(style='width:97%;')
        v-row.align-stretch(no-gutters='no-gutters'  justify="end")
            v-col.pr-0(v-if="aibotData.channel == 'UpSide'" cols='6')
              v-card.deal-tab-card.rounded-card(style='width:98.5%;' flat min-height='300px')
                v-row(no-gutters='no-gutters' align="center" justify="start")
                  v-col(cols='12' md='12')
                      .pt-6.pb-3
                        v-card-text.mt-0.pa-0
                          v-row.pl-3
                            v-col(cols='3').pr-0.pl-0
                              .d-flex.align-start.justify-center
                                h4.f-text-highlight-word.pl-3.pt-1 {{$t("ADD_AIBOTS.DETAIL")}}
                              .d-flex.align-start.justify-center.bot-icon.pt-2
                                div.d-flex.justify-center.align-center
                                  v-img(src="../assets/images/ann.png"   max-width="100px")
                            v-col(cols='9')
                              .scrollbar.MeetingRecapMinH_ChatBot  
                                v-form.pt-6.mt-6(v-model='valid' ref='formContact')
                                  v-row(no-gutters)
                                    v-col.pr-md-4(cols='12' md='12').my-1.mb-1
                                      p.d-flex.align-center.input-has-label.input-has-label-no-border.position-relative
                                        label.pt-3
                                          h6.t-black {{$t('ADD_AIBOTS.NAME')}} :
                                        v-text-field.v-text-field_low_z-index(solo falt dense v-model='aibotData.bot_name' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules100" style='padding-top:0.1rem;') 
                                    v-col.pr-md-4(cols='12' md='12').mb-1            
                                      p.d-flex.align-center.input-has-label.input-has-label-no-border
                                          label.pt-2
                                            h6.t-black {{$t('GPT_AIBOTS.REPLY_LANGUAGE')}} :
                                          v-select.no-border( solo flat
                                          v-model='aibotData.answer_language'
                                          :items='removeAutoAssistantLanguageList' 
                                          item-text='name' 
                                          item-value='key'
                                          :readonly='!isEdit'
                                          :class='!isEdit ? "remove-arrow" : ""'
                                          )
                                            template(v-slot:append-outer)
                                              v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                              v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                              v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                              v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                    v-col.pr-md-4(cols='12' md='12')
                                      p.d-flex.align-center.input-has-label.input-has-label-no-border
                                          label.pt-2
                                            h6.t-black {{$t('GPT_AIBOTS.MODEL')}} :
                                          v-select.no-border( solo flat 
                                          v-model='aibotData.deployment_name'
                                          :items='model_list_for_upside' 
                                          item-text='name' 
                                          item-value='id' 
                                          :readonly='!isEdit' 
                                          :class='!isEdit ? "remove-arrow" : ""')
                                            template(v-slot:append-outer)
                                              v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                              v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                              v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                              v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
            //-  在channel不是UpSide狀況下顯示                      
            v-col.pr-0(v-else cols='6')
              v-card.deal-tab-card.rounded-card(style='width:98.5%;' flat min-height='300px')
                v-row(no-gutters='no-gutters' align="center" justify="start")
                  v-col(cols='12' md='12')
                      .pt-6.pb-3
                        v-card-text.mt-0.pa-0
                          v-row.pl-3
                            v-col(cols='3').pr-0.pl-0
                              .d-flex.align-start.justify-center
                                h4.f-text-highlight-word.pl-3.pt-1 {{$t("ADD_AIBOTS.DETAIL")}}
                              .d-flex.align-start.justify-center.bot-icon.pt-2
                                //- v-badge(bordered  avatar color="white" offset-x="13" offset-y="67")
                                //-   template(v-slot:badge)
                                //-     v-avatar()
                                //-       v-img(v-if='select_inbox.channel == "teams"' src='@/assets/images/teams_logo.png' width='25px')
                                //-       v-icon(v-else :color='get_upchat_channel_icon(select_inbox.channel).color' size='23') {{get_upchat_channel_icon(select_inbox.channel).icon}}
                                div(style='border: #14c6de solid 5px;border-radius:50%;min-width:120px;height:120px;').d-flex.justify-center.align-center
                                  v-img(src="../assets/images/botsetting.png"   max-width="85px")
                            v-col(cols='9')
                              div.d-flex.justify-end.pr-3
                                  v-btn(fab :icon='!aibotData.active' :color='aibotData.active? "#5fe98b":"#6c757d"' @click='openAibot()' width='24' height='24' :ripple='false'  depressed dark :title='$t("ADD_AIBOTS.ONOFF")')
                                    v-icon(size='21' style='padding-top:1px;') mdi-power
                                  v-btn.ml-2( icon  @click='onDelete()' width='24' height='24' :ripple='false'  depressed dark :title='$t("GENERAL.DELETE")')
                                    v-icon(size='16' color='#6c757d') icon-remove
                              //- .scrollbar.MeetingRecapMinH_ChatBot  
                              v-form.pt-2(v-model='valid' ref='formContact')
                                v-row(no-gutters)
                                  v-col.pr-md-4(cols='12' md='12').my-1.mb-1
                                    p.d-flex.align-center.input-has-label.input-has-label-no-border.position-relative
                                      label.pt-3
                                        h6.t-black {{$t('ADD_AIBOTS.NAME')}} :
                                      v-text-field.v-text-field_low_z-index(solo falt dense v-model='aibotData.bot_name' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules100" style='padding-top:0.1rem;') 
                                          template(v-slot:append)
                                              v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                              v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                              v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                              v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                  v-col.pr-md-4(cols='12' md='12').mb-3
                                    p.d-flex.align-center.input-has-label.input-has-label-no-border
                                      label.pt-3
                                        h6.t-black {{$t('ADD_AIBOTS.INBOX')}} :
                                      v-autocomplete(
                                          solo flat dense
                                          v-model='select_inbox' 
                                          :rules='formRule.nameRules', 
                                          :items='inbox_list' 
                                          item-text='name' 
                                          item-value='id'  
                                          return-object
                                          :menu-props='{closeOnClick:true}'
                                          :readonly='!isEdit'
                                          style='padding-top:0.1rem;'
                                          :class='!isEdit ? "remove-arrow" : ""'
                                        )
                                          template(v-slot:selection="data")
                                              .d-flex.justify-space-between
                                                v-img(v-if='data.item.channel == "teams"' src='@/assets/images/teams_logo.png' width='25px')
                                                v-icon(v-else :color='get_upchat_channel_icon(data.item.channel).color') {{get_upchat_channel_icon(data.item.channel).icon}}
                                                v-list-item-title.pl-1.pt-1(v-html="data.item.name")
                                          template(v-slot:item="data")
                                            v-img(v-if='data.item.channel == "teams"' src='@/assets/images/teams_logo.png' width='25px')
                                            v-icon(v-else :color='get_upchat_channel_icon(data.item.channel).color') {{get_upchat_channel_icon(data.item.channel).icon}}
                                            v-list-item-title.pl-4(v-html="data.item.name")
                                          template(v-slot:append-outer)
                                              v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                              v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                              v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                              v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                  v-col.pr-md-4(cols='12' md='12').mb-3            
                                    p.d-flex.align-center.input-has-label.input-has-label-no-border
                                        label.pt-2
                                          h6.t-black {{$t('GPT_AIBOTS.REPLY_LANGUAGE')}} :
                                        v-select.no-border( solo flat dense
                                        v-model='aibotData.answer_language'
                                        :items='assistant_language_list' 
                                        item-text='name' 
                                        item-value='key'
                                        :readonly='!isEdit'
                                        :class='!isEdit ? "remove-arrow" : ""'
                                        )
                                          template(v-slot:append-outer)
                                            v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                            v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                            v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                            v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                  v-col.pr-md-4(cols='12' md='12').mb-3            
                                    p.d-flex.align-center.input-has-label.input-has-label-no-border
                                        label.pt-2
                                          h6.t-black {{$t('GPT_AIBOTS.MODEL')}} :
                                        v-select.no-border( solo flat dense 
                                        v-model='aibotData.deployment_name'
                                        :items='model_list' 
                                        item-text='name' 
                                        item-value='id' 
                                        :readonly='!isEdit' 
                                        :class='!isEdit ? "remove-arrow" : ""')
                                          template(v-slot:append-outer)
                                            v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                            v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                            v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                            v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                  v-col.pr-md-4(cols='12' md='12' :title='aibotData.description').my-1
                                        .d-flex.align-center.justify-md-space-between
                                          h6 {{$t('CONTACT_DETAIL.DESCRIPTION')}} :
                                          div
                                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                            v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                                        p.d-flex.align-start.input-has-label
                                          //- label {{$t('CONTACT_DETAIL.DESCRIPTION')}}
                                          v-textarea.span.textarea-style(v-model='aibotData.description' :readonly='!isEdit' solo no-resize rows='2')
                                            template(v-slot:append)
            v-col(cols='6').pl-0
              v-card.deal-tab-card.rounded-card(style='width:99%; margin-left:6px;' flat min-height='300px')
                v-row.account-info(no-gutters='no-gutters' align="center" justify="start")
                  .pt-6.pb-3.mx-4
                    v-card-text.mt-0.pa-0
                        v-row()
                          v-col(cols='12' class).pb-0
                            v-card-title.p-0
                              v-col(cols='1').px-0
                                v-img(src="../assets/images/book_big.png" class="white--text align-end"  width="100%")
                              v-col.py-0
                                h4.f-text-highlight-word.pt-3(style='margin-bottom:-2px;') {{$t('ADD_AIBOTS.BRAIN_SIZE')}}
                                div(style='margin-top:-10px;')
                                  span(class='small_word_gray ellipsis') {{$t('ADD_AIBOTS.M_1')}}
                          v-col(cols='12').mt-1
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.mb-2
                              label.mt-2
                                h6
                                  span.t-black {{$t('ADD_AIBOTS.KNOWLEDGE')}} :
                              v-autocomplete.no-border(flat dense  v-model='aibotData.knowledges' return-object multiple solo :readonly='!isEdit' :class='!isEdit ? "remove-arrow" : ""' :items='knowledgeList' item-text='name' item-value='id'  :menu-props='{closeOnClick:true}')
                                  template(v-slot:append)
                                      v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                      v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                              v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='12' style='margin-top:-27px;').pt-0.pb-4
                            span(class='small_word_gray') {{$t('ADD_AIBOTS.M_2')}}
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel != 'UpSide'")
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-2
                                h6.t-black {{$t('ADD_AIBOTS.MEMORY_LENGTH')}} :
                              v-select.no-border(
                                  solo flat 
                                  :class='!isEdit ? "remove-arrow" : ""'
                                  :items='memory_length_list' 
                                  v-model='aibotData.memory_length',
                                  :rules='formRule.nameRules', 
                                  required='required', 
                                  large='large'
                                  persistent-hint
                                  :readonly='!isEdit'
                                  dense
                              )
                                  template(v-slot:append-outer)
                                      v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                      v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                      v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel != 'UpSide'")
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.RETURN_KNOWLEDGE')}} :
                              v-select.no-border(
                                  solo flat 
                                  :class='!isEdit ? "remove-arrow" : ""'
                                  :items='return_knowledge_list' 
                                  v-model='aibotData.return_knowledge',
                                  :rules='formRule.nameRules', 
                                  required='required', 
                                  large='large'
                                  persistent-hint
                                  :readonly='!isEdit'
                                  dense
                              )
                                  template(v-slot:append-outer)
                                      v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                      v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                      v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='12' style='margin-top:-25px;' v-if="aibotData.channel != 'UpSide'").pt-0
                            span(class='small_word_gray' ) {{$t('ADD_AIBOTS.M_3')}}
                          v-col(cols='6' md='6' sm='12')
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-2
                                h6.t-black {{$t('ADD_AIBOTS.RETURN_LEN')}} :
                              v-select.no-border(
                                  solo flat
                                  :class='!isEdit ? "remove-arrow" : ""'
                                  :items='answer_length'
                                  v-model='aibotData.answer_length',
                                  required='required',
                                  item-text='text'
                                  item-value='value'
                                  large='large'
                                  persistent-hint
                                  :readonly='!isEdit'
                                  dense
                              )
                                  template(v-slot:append-outer)
                                      v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                      v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                      v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12')
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-2
                                h6.t-black {{$t('ADD_AIBOTS.RETURN_TONE')}} :
                              v-select.no-border(
                                  solo flat
                                  :class='!isEdit ? "remove-arrow" : ""'
                                  :items='answer_tone'
                                  v-model='aibotData.answer_tone',
                                  required='required',
                                  item-text='text'
                                  item-value='value'
                                  large='large'
                                  persistent-hint
                                  :readonly='!isEdit'
                                  dense
                              )
                                  template(v-slot:append-outer)
                                      v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                      v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                      v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                      v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel != 'UpSide'")
                            p.input-selection.ellipsis.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.AI_DISCLAIMER')}} :
                              v-checkbox(v-model='aibotData.need_ai_disclaimer' :readonly='!isEdit' dense :hide-details='true')
                                template(v-slot:append)
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel != 'UpSide'")
                            p.input-selection.ellipsis.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border     
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.RETURN_REPLY_WITH_IMAGE')}} :
                              v-checkbox(v-model='aibotData.return_related_image_with_reply' :readonly='!isEdit' dense :hide-details='true')
                                template(v-slot:append)
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel != 'UpSide'")
                            p.input-selection.ellipsis.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.CITATION')}} :
                              v-checkbox(v-model='aibotData.need_citation' :readonly='!isEdit' dense :hide-details='true')
                                template(v-slot:append)
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' )
                            p.input-selection.ellipsis.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.FILELINK')}} :
                              v-checkbox(v-model='aibotData.need_file_link' :readonly='!isEdit' dense :hide-details='true')
                                template(v-slot:append)
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel == 'teams'")
                            p.input-selection.ellipsis.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.IMAGE_SEARCH')}} :
                              v-checkbox(v-model='aibotData.enable_image_search' :disabled='!can_image_search' :readonly='!isEdit' dense :hide-details='true')
                                template(v-slot:append v-if='can_image_search')                                  
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                          v-col(cols='6' md='6' sm='12' v-if="aibotData.channel == 'teams'")
                            p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border
                              label.pt-3
                                h6.t-black {{$t('ADD_AIBOTS.RETURN_IMAGE_QUANTITY')}} :  
                              v-select.no-border(solo flat
                                :class='!isEdit ? "remove-arrow" : ""'
                                :items='return_image_quantity'
                                v-model='aibotData.return_image',
                                persistent-hint
                                :readonly='!isEdit'
                                :disabled='!can_image_search'
                              )
                                template(v-slot:append-outer v-if='can_image_search')
                                  v-icon.pt-1.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                  v-icon.pt-1(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close
                                  v-progress-circular.pt-1(size='18' v-if='loading' indeterminate color='primary')
                                  v-icon.pt-1(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
      #page-inner.pa-3.d-flex.align-start.justify-start.pl-13.pt-0(style='width:97%;')
        v-row(no-gutters='no-gutters' align="center" justify="center" style='padding-top:0.3%;')
            v-col(cols='12' md='12')
                v-card.deal-tab-card.rounded-card(flat)
                  v-row(no-gutters='no-gutters' align="center" justify="start")
                    v-col(cols='12' md='12')
                        v-card-title.pa-0.pt-1.d-flex.align-center.justify-md-space-between
                          div.pa-2.d-flex.align-center(width="30px")
                            div.pl-5.pt-3
                              h4.f-text-highlight-word(style='margin-bottom:-10px;') {{$t("ADD_AIBOTS.C_SETTING")}}
                              span(class='small_word_gray ellipsis' ) {{$t('ADD_AIBOTS.M_4')}}
                        v-card-text.pb-8
                          v-row
                              v-col(cols='3')
                                p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.mb-2
                                  label.pt-3
                                    h6.t-black {{$t('GPT_AIBOTS.AIASSISTANTNAME')}} :
                                  v-text-field(solo falt dense persistent-hint :hint=`$t('ADD_AIBOTS.M_8')` v-model='aibotData.assistant_name' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules100" style='padding-top:0.1rem;') 
                                      template(v-slot:append v-if="aibotData.channel != 'UpSide'")
                                          v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                          v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                          v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                          v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                              v-col(cols='3')
                                p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.mb-2
                                  label.pt-3
                                    h6.t-black {{$t('ADD_AIBOTS.ROLE')}} :
                                  v-text-field(solo falt dense persistent-hint :hint=`$t('ADD_AIBOTS.M_5')` v-model='aibotData.assistant_role' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules500" style='padding-top:0.1rem;') 
                                      template(v-slot:append v-if="aibotData.channel != 'UpSide'")
                                          v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                          v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                          v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                          v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                              v-col(cols='3')
                                p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.mb-2
                                  label.pt-3
                                    h6.t-black {{$t('ADD_AIBOTS.SCOPE')}} :
                                  v-text-field(solo falt dense persistent-hint :hint=`$t('ADD_AIBOTS.M_6')` v-model='aibotData.conversation_topic_limit' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules500" style='padding-top:0.1rem;') 
                                      template(v-slot:append v-if="aibotData.channel != 'UpSide'")
                                          v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                          v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                          v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                          v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit
                              v-col(cols='3')
                                p.d-flex.align-center.justify-md-space-between.position-relative.input-has-label.input-has-label-no-border.mb-2
                                  label.pt-2
                                    h6.t-black {{$t('ADD_AIBOTS.TOPIC')}} :
                                  v-text-field(solo falt dense persistent-hint :hint=`$t('ADD_AIBOTS.M_7')` v-model='aibotData.conversation_topic' :readonly='!isEdit' ref='firstTextField' :rules="formRule.rules500" style='padding-top:0.1rem;') 
                                      template(v-slot:append v-if="aibotData.channel != 'UpSide'")
                                          v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEditDetail()') icon-check
                                          v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEditDetail()') mdi-window-close  
                                          v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                                          v-icon(size='18' v-if='!isEdit && !loading' v-hide-element @click='onEdit()') icon-edit

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import KnowledgeLibraryService from "../services/KnowledgeLibraryService";
import AiBotsService from "../services/AiBotsService";
import AssistantLanguageList from "@/array/the_assistant_languageList";
import GPTModelList from "@/array/the_gpt_modelList";
import GPTModelListForUpside from "@/array/the_gpt_modelList_for_upside";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      // other
      valid:true,
      isEdit:false,
      isEditSub:false,
      loading:false,
      delLoading:false,
      breadcrumbs: [{
              text: i18n.t('GPT_MENU.BACKTO') + i18n.t('GPT_AIBOTS.TITLE'),
              disabled: false,
              href: '/upgpt/Chatbot',
          }
      ],
      formRule: {
          nameRules: [
              (v) => !!v || i18n.t('RULE.RULE_R'),
              (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
          ],
          rules100: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
          ],
          rules500: [
              (v) => !!v || i18n.t('RULE.RULE_R'),
              (v) => /^.{0,500}$/.test(v) || i18n.t('RULE.RULE_500'),
          ],
      },
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      aibotData:null,
      originAiBotData:null,
      chatBotList:[],
      knowledgeList:[],
      return_knowledge_list:[],
      memory_length_list:[],
      return_image_quantity: [],
      chatBotName:"",
      target_id : this.$route.params.id,
      mode:null,
      inbox_list:[],
      select_inbox:null,
      assistant_language_list: AssistantLanguageList,
      model_list : GPTModelList,
      model_list_for_upside : GPTModelListForUpside,
      can_image_search:false,
      answer_tone:[
      { text: this.$t('TONE_LEVEL.PROFESSIONAL'), value: "Professional" },
      { text: this.$t('TONE_LEVEL.FRIENDLY'), value: "Friendly" },
      { text: this.$t('TONE_LEVEL.CASUAL'), value: "Casual" },
      { text: this.$t('TONE_LEVEL.INSPIRING'), value: "Inspiring" },
      { text: this.$t('TONE_LEVEL.PASSIONATE'), value: "Passionate" },
      { text: this.$t('TONE_LEVEL.WARM'), value: "Warm" }
      ],
      answer_length: [
      { text: this.$t('DETAIL_LEVEL.DETAILED'), value: "Long" },
      { text: this.$t('DETAIL_LEVEL.NORMAL'), value: "Detailed" },
      { text: this.$t('DETAIL_LEVEL.CONCISE'), value: "Short" },
      ],
    };
  },
  computed:{
    removeAutoAssistantLanguageList(){
      let newList = [...this.assistant_language_list];
      let removeAutoLanguageList=newList.filter(item => item.key !== 'auto')
      return removeAutoLanguageList;
    }
  },
  async created() {
    this.loading = true;
    for(let i = 1; i<11;i++){
      if(i<=3){
        this.memory_length_list.push(i);
      }
      if(i>=3){
        this.return_knowledge_list.push(i);
      }
    }
    for(let i = 1; i<=30;i++){
      this.return_image_quantity.push(i);
    }
    await this.get_upchat_inbox();
    await this.getKnowledgeList();
    await this.getDetailData();
    this.get_upgpt_setting();
    this.loading = false;
    this.getAnswerLen_Tone()
  },
  destroyed() {
  },
  methods: {
    async get_upgpt_setting(){
        let setting = await this.upgpt_image_func_setting();
        this.can_image_search = setting['can_image_search'];
    },
    set_title(){
        const title = localStorage.getItem('title');
        let title_name = this.aibotData.bot_name;
        let tab_name = '';
        tab_name = this.aibotData.bot_name;
        document.title = title+' : '+title_name +' – UpGPT';
        return tab_name;
    },
    async getDetailData(){
      await AiBotsService.detail(this.target_id).then((response)=>{
          let data = response.data;
          data.knowledges = data.linked_bots_and_knowledge_set.map(item=> {return item.knowledge_lib_id});
          data.knowledges_count = data.linked_bots_and_knowledge_set.length;
          this.aibotData = response.data;
          this.select_inbox = this.inbox_list.filter(el=>el.id == this.aibotData.inbox_id)[0];
          this.set_title();
      }).catch(response => {
          // Redirect to PermissionError page if error status is 403
          if (response.response.status == 403)
          {
              this.$router.replace('/upgpt/PermissionError');
          }
          else if(response.response.status == 404)
          {
              this.$router.replace('/upgpt/PageNotFound');
          }
      });
    },
    async get_upchat_inbox(){
      await AiBotsService.get_upchat_inbox().then((response)=>{
        this.inbox_list = [];
        let used = new Set(response.data.used);
        let upchat = response.data.upchat.payload.map(el=>{return {"id":el.id,"name":el.name,"channel":this.get_upchat_channel(el.channel_type),"disabled":used.has(el.id.toString())}});
        let teams = response.data.teams;
        if(teams.length > 0){
          for(let teams_item in teams){
            teams[teams_item].disabled = used.has(teams[teams_item].id);
            if(teams[teams_item].name == "teams"){
              teams[teams_item].name = "Teams";
            }
          }
        }
        this.inbox_list.push({ header: 'UpChat'});
        this.inbox_list = this.inbox_list.concat(upchat);
        this.inbox_list.push({ divider: true });
        this.inbox_list.push({ header: 'Teams'});
        this.inbox_list = this.inbox_list.concat(teams);
      })
    },
    get_upchat_channel(data){
        let channel_str = data.toLowerCase()
        let channel_name = ''
        switch(true){
            case channel_str.includes('facebook'):
                return "Facebook"
            case channel_str.includes('line'):
                return "Line"
            case channel_str.includes('webchat'):
                return "Webchat"
            case channel_str.includes('whatsapp'):
                return "WhatsApp"
            case channel_str.includes('sms'):
                return "SMS"
            case channel_str.includes('mail'):
                return "Mail"
            default:
                if(data.split('::').length>1){
                    channel_name = data.split('::')[1];
                }else{
                    channel_name = 'Web';
                }
                return channel_name
        }
    },
    get_upchat_channel_icon(data){
        let channel_str = data.toLowerCase()
        switch(true){
            case channel_str.includes('facebook'):
                return {icon:'mdi-facebook',color:'blue'}
            case channel_str.includes('line'):
                return {icon:'fab fa-line',color:'green'}
            case channel_str.includes('webchat'):
                return {icon:'mdi-earth',color:'blue'}
            case channel_str.includes('whatsapp'):
                return {icon:'mdi-whatsapp',color:'green'}
            case channel_str.includes('sms'):
                return {icon:'mdi-message-text-outline',color:'blue'}
            case channel_str.includes('mail'):
                return {icon:'mdi-email',color:'blue'}
            default:
                return {icon:'mdi-earth',color:'blue'}
        }
    },
    getknowledgeName(){
      let names = this.aibotData.knowledges.map(el=>{return el.name}).join();
      return names
    },
    async getBotList(){
      await AiBotsService.select_list().then((response)=>{
        this.chatBotList = response.data;
      })
    },
    async getKnowledgeList(){
      await KnowledgeLibraryService.select_list().then((response)=>{
        this.knowledgeList = response.data;
      })
    },
    async openKnowledge(data){
      let edit_data = {
        "id" : data.id,
        "active" : !data.active
      }
      await KnowledgeLibraryService.edit(edit_data).then((response)=>{
        data.active = response.data.active;
      })
    },
    async openAibot(){
      let edit_data = {
        "id" :this.target_id,
        "active" : !this.aibotData.active
      }
      this.aibotData.active = !this.aibotData.active;
      await AiBotsService.active(this.target_id,edit_data).then((response)=>{
        this.aibotData.active = response.data.active;
      })
    },
    onDelete(){
      this.mode = "delete";
      this.message = i18n.t("ADD_AIBOTS.DEL_MSG");
      this.messageDialog = true;
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }
    },
    onEdit(){
        this.isEdit = true;
        this.originAiBotData = JSON.parse(JSON.stringify(this.aibotData));
    },
    async confirmEditDetail(){
      let edit_data = this.aibotData;
      if(edit_data.bot_name == 'UpSide' && this.select_inbox == undefined){
        edit_data.inbox_id = 'UpSide'
        edit_data.channel = 'UpSide'

        edit_data.need_ai_disclaimer = true;
        edit_data.citation = true;
        edit_data.return_related_image_with_reply = true;
      }else{
        edit_data.inbox_id = this.select_inbox.id;
        edit_data.channel = this.select_inbox.channel;
      }
      if(edit_data.channel != 'teams'){
        edit_data.enable_image_search = false;
        edit_data.return_image = 1;
      }
      await AiBotsService.edit(this.target_id,edit_data).then((response)=>{
          if(response.data == "Has agent bot"){
            this.showErrorDialog(i18n.t('GPT_AIBOTS.HAS_AGENT_BOT'));
            this.addBtn_loading = false;
          }else if(response.data == "No agent bot"){
            this.showErrorDialog(i18n.t('GPT_AIBOTS.NO_AGENT_BOT'));
            this.addBtn_loading = false;
          }else{
            let data = response.data;
            data.knowledges = data.linked_bots_and_knowledge_set.map(item=> {return item.knowledge_lib_id});
            data.knowledges_count = data.linked_bots_and_knowledge_set.length;
            this.aibotData = response.data;
            this.select_inbox = this.inbox_list.filter(el=>el.id == this.aibotData.inbox_id)[0];
            this.isEdit = false;
          }
          if(this.originAiBotData.inbox_id != this.aibotData.inbox_id){
            this.get_upchat_inbox();
          }
      })
    },
    cancelEditDetail(){
      this.isEdit = false;
      this.aibotData = this.originAiBotData;
    },
    onEditSub(){
        this.isEditSub = true;
        this.originAiBotData = JSON.parse(JSON.stringify(this.aibotData));
    },
    async confirmEditDetailSub(){
      let edit_data = {
        "id" : this.aibotData.id,
        "linked_ai_bot" : this.aibotData.robots,
      }
      await KnowledgeLibraryService.set_detail_bots(edit_data).then((response)=>{
        this.aibotData.robots = response.data.linked_ai_bot;
        this.aibotData.updated_on = response.data.updated_on;
      })
      this.isEditSub = false;
    },
    cancelEditDetailSub(){
      this.isEditSub = false;
      this.aibotData = this.originAiBotData;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
      if(this.mode == 'delete'){
        this.mode = null;
        this.$router.push('/upgpt/Chatbot');
      }
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val){
        this.delLoading = true;
        this.messageDialog = false;
        if(this.mode == "delete"){
          await AiBotsService.delete(this.target_id).then(()=>{
            this.$router.push('/upgpt/Chatbot');
            this.delLoading = false;
            this.mode = null;
          }).catch(() => {
              this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.DELETE_ERROR'));
          });
        }
      }
      this.messageDialog = false;
    },
    async reFresh(){
      this.loading = true;
      await this.getDetailData();
      this.loading = false;
    },
    getAnswerLen_Tone() {
      this.answer_length = [
      { text: this.$t('DETAIL_LEVEL.DETAILED'), value: "Long" },
      { text: this.$t('DETAIL_LEVEL.NORMAL'), value: "Detailed" },
      { text: this.$t('DETAIL_LEVEL.CONCISE'), value: "Short" },
      ];
      this.answer_tone = [
      { text: this.$t('TONE_LEVEL.PROFESSIONAL'), value: "Professional" },
      { text: this.$t('TONE_LEVEL.FRIENDLY'), value: "Friendly" },
      { text: this.$t('TONE_LEVEL.CASUAL'), value: "Casual" },
      { text: this.$t('TONE_LEVEL.INSPIRING'), value: "Inspiring" },
      { text: this.$t('TONE_LEVEL.PASSIONATE'), value: "Passionate" },
      { text: this.$t('TONE_LEVEL.WARM'), value: "Warm" }
      ];
    }
  },
  watch:{
  }
};
</script>